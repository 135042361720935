import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import bookCover1 from "assets/img/faces/my-escape-cover.jpg";
import bookCoverQuestion from "assets/img/examples/question.png";
import { Link } from "gatsby";
const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgBooks
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>My most recent books</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
              <Link to="/myescape"><img src={bookCover1} alt="..." className={imageClasses} /></Link>
              </GridItem>
              <h4 className={classes.cardTitle}>
                My Escape
                <br />
                <small className={classes.smallTitle}>Real life story & Drama</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  This book was born from personal experience.
  For a woman who made the decision to migrate alone.
  Experiences have been documented in this book. The story inspires or help anyone in making a decision to change his life.
  It talks about the experience of a woman in escaping war, immigration, and death boat rides in the mediterranean area.
                  </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <Link to="/messagewillnotarrive"><img src={bookCoverQuestion} alt="..." className={imageClasses} /></Link>
              </GridItem>
              <h4 className={classes.cardTitle}>
                Message will not arrive
                <br />
                <small className={classes.smallTitle}>Romance & Drama</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  A novel talks about a love story that brings a middle eastern woman and a european man together.
  The events take place in several geographical places. You will be blown away by the details of this novel, the difference of the mentalities and cultures between these two, whom they love.
  The novel is still in progress.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={bookCoverQuestion} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                After they killed David in his prior life
                <br />
                <small className={classes.smallTitle}>Fictional Novel</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                This book talks about the murder of someone which happened in the seventies. Including magic and a reincarnation in a remote village in Syria, this book will be thrilling for anyone to read.
                
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
